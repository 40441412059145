import { IGAMStoreData } from '@types';

const GAM_STORE_KEY = 'GAMTrackData';

class GAMService {
  private store = typeof window !== 'undefined' ? window.sessionStorage : undefined;
  private longTermStore = typeof window !== 'undefined' ? window.localStorage : undefined;

  setToStore(data: IGAMStoreData) {
    if (this.store) {
      try {
        const currentData = this.getFromStore();
        this.store.setItem(GAM_STORE_KEY, JSON.stringify(currentData ? { ...currentData, ...data } : data));
      } catch (error) {
        console.error('Error on set to store', error);
      }
    }
  }

  setToLongTermStore(data: IGAMStoreData) {
    if (this.longTermStore) {
      try {
        const currentData = this.getFromLongTermStore();
        this.longTermStore.setItem(GAM_STORE_KEY, JSON.stringify(currentData ? { ...currentData, ...data } : data));
      } catch (error) {
        console.error('Error on set to long-term store', error);
      }
    }
  }

  getFromStore(): IGAMStoreData | undefined {
    try {
      if (this.store) {
        const GAMTrackData = this.store.getItem(GAM_STORE_KEY);
        if (
          GAMTrackData &&
          GAMTrackData !== 'null' &&
          GAMTrackData !== 'undefined' &&
          GAMTrackData !== "''" &&
          GAMTrackData !== '""'
        ) {
          return JSON.parse(GAMTrackData);
        }
      }
    } catch (error) {
      console.error('Error on get from store', error);
    }
  }

  getFromLongTermStore(): IGAMStoreData | undefined {
    try {
      if (this.longTermStore) {
        const GAMTrackData = this.longTermStore.getItem(GAM_STORE_KEY);
        if (
          GAMTrackData &&
          GAMTrackData !== 'null' &&
          GAMTrackData !== 'undefined' &&
          GAMTrackData !== "''" &&
          GAMTrackData !== '""'
        ) {
          return JSON.parse(GAMTrackData);
        }
      }
    } catch (error) {
      console.error('Error on get from store', error);
    }
  }

  removeItem(itemId: string): void {
    if (this.store) {
      const currentData = this.getFromStore();
      if (currentData) {
        delete currentData[itemId];
        this.store.setItem(GAM_STORE_KEY, JSON.stringify(currentData));
      }
    }
  }

  removeItemFromLongTerm(itemId: string): void {
    if (this.longTermStore) {
      const currentData = this.getFromLongTermStore();
      if (currentData) {
        delete currentData[itemId];
        this.longTermStore.setItem(GAM_STORE_KEY, JSON.stringify(currentData));
      }
    }
  }

  clearStore() {
    if (this.store) {
      this.store.removeItem(GAM_STORE_KEY);
    }
  }

  clearLongTermStore() {
    if (this.longTermStore) {
      this.longTermStore.removeItem(GAM_STORE_KEY);
    }
  }
}

const GAMStorageService = new GAMService();

export { GAMStorageService };
