export const ATTRIBUTE_COUNTRY_PATH = 'country';
export const ATTRIBUTE_FLOWER_TYPE_PATH = 'flowertype';
export const FILTER_CATEGORY_PATH = 'category';
export const ATTRIBUTE_MANUFACTURER_PATH = 'manufacturer';
export const ATTRIBUTE_COLOR_PATH = 'color';
export const ATTRIBUTE_HEIGHT_PATH = 'height';
export const ATTRIBUTE_DIAMETER_PATH = 'diameter';
export const ATTRIBUTE_PRICE_PATH = 'price';
export const ATTRIBUTE_PRICE_RANGE_PATH = 'pricerange';
export const ATTRIBUTE_CATEGORY_PATH = 'category';
export const ATTRIBUTE_SIZE_PATH = 'size';
export const ATTRIBUTE_WIDTH_PATH = 'width';
export const ATTRIBUTE_COATING_PATH = 'coating';
export const ATTRIBUTE_SHAPE_PATH = 'shape';
export const ATTRIBUTE_LENGTH_PATH = 'length';
export const ATTRIBUTE_VOLUME_PATH = 'volume';
export const ATTRIBUTE_PACKING_PATH = 'packing';
export const ATTRIBUTE_QUALITY_PATH = 'quality';
export const ATTRIBUTE_MATURITY_PATH = 'maturity';
export const ATTRIBUTE_WEIGHT_PATH = 'weight';
export const INSTOCK_PATH = 'instock';
export const PREORDER_PATH = 'preorder';
