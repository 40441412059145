import { signOut } from 'next-auth/react';

import { postErrorMessage } from '@api/logging';
import { PRODUCTION_ENV } from '@lib/constants/environment';
import { ERROR_MESSAGES, ERROR_MESSAGES_400 } from '@lib/error-messages';

export interface IErrorWithCause {
  [key: string]: string;
}

export function getErrorMessage(response: IErrorWithCause): string {
  if (!response || typeof response !== 'object') return ERROR_MESSAGES.commonError;

  if ('password_incorrect' in response) {
    return ERROR_MESSAGES.current_password_incorrect;
  }

  const errorKeys = Object.keys(response);

  if (errorKeys && errorKeys.length) {
    const error = ERROR_MESSAGES_400[errorKeys[0] as keyof typeof ERROR_MESSAGES_400];
    if (error && typeof error !== 'string') {
      const errorMessageKey = response[errorKeys[0]] as keyof typeof error;
      return errorMessageKey && errorMessageKey in error ? error[errorMessageKey] : ERROR_MESSAGES.commonError;
    } else if (error && typeof error === 'string') {
      return error;
    }
    return ERROR_MESSAGES.commonError;
  }

  return ERROR_MESSAGES.commonError;
}

export async function handleError<T>(res?: Response, requestData?: unknown): Promise<T> {
  try {
    if (!res) {
      throw new Error('Failed to fetch data');
    }

    if (res.status === 401) {
      // ! TODO test this on staging
      signOut({ redirect: false });
      throw new Error(ERROR_MESSAGES.unauthorized, { cause: 'unauthorized' });
    }

    if (res.status === 400) {
      try {
        const respData = (await res.json()) as IErrorWithCause;
        throw new Error(getErrorMessage(respData));
      } catch (error) {
        if ((error instanceof Error && error.message === ERROR_MESSAGES.commonError) || error instanceof SyntaxError) {
          postErrorMessage({
            message: `${!PRODUCTION_ENV ? '[STAGING] ' : ''}` + '400 | Received non-JSON response',
            data: {
              timestamp: new Date().toISOString(),
              url: res.url,
              error: String(error),
              response: { status: res.status, statusText: res.statusText },
              requestData
            }
          }).catch(console.error);
        }
        throw new Error(error instanceof Error ? error.message : ERROR_MESSAGES.commonError);
      }
    }

    if (res.status === 404) {
      throw new Error('404');
    }

    if (res.status !== 200 && res.status !== 201 && res.status !== 204) {
      const errorMessage = new Error(`${res.status}|${res.statusText}`);
      postErrorMessage({
        message: `${!PRODUCTION_ENV ? '[STAGING] ' : ''}` + `{API Request}${res.status}|${res.statusText}`,
        data: {
          url: res.url,
          response: { status: res.status, statusText: res.statusText },
          requestData
        }
      }).catch(console.error);
      throw errorMessage;
    }

    if (!res.ok) {
      throw new Error('Failed to fetch data');
    }

    let json;
    try {
      json = (await res.json()) as T;
    } catch (err) {
      postErrorMessage({
        message: `${!PRODUCTION_ENV ? '[STAGING] ' : ''}` + res.status + ' | Received non-JSON response',
        data: {
          timestamp: new Date().toISOString(),
          url: res.url,
          error: String(err),
          response: { status: res.status, statusText: res.statusText },
          requestData
        }
      }).catch(console.error);
      throw new Error(res.status + ' | Received non-JSON response');
    }

    return json;
  } catch (error) {
    throw error;
  }
}
