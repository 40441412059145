export const iOS = (userAgent: string) => !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
export const isMobileDevice = (navigator?: { userAgent?: string }) =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent || '');
export const isWindowsDevice = (navigator?: { userAgent?: string }) => {
  const userAgent = navigator?.userAgent || '';
  return /Windows Phone|Windows NT/i.test(userAgent);
};

export const isMacOSDevice = (navigator?: { userAgent?: string }) => {
  const userAgent = navigator?.userAgent || '';
  return /Macintosh|Mac OS/i.test(userAgent);
};
export const API_URL = String(process.env.NEXT_PUBLIC_API_ENDPOINT);
export const LOCAL_ENV = process.env.NODE_ENV === 'development';
export const STAGING_ENV = !LOCAL_ENV && API_URL.includes('stg');
export const PRODUCTION_ENV = !STAGING_ENV && process.env.NODE_ENV === 'production';
export const CLIENT_URL = STAGING_ENV ? 'https://stg.flowerpot.ua' : 'https://flowerpot.ua';
export const GOOGLE_MAPS_API_KEY = String(process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY);
