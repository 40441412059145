import { PRODUCTION_ENV } from '@lib/constants/environment';
import {
  transformAddPaymentInfoPayload,
  transformAddShippingInfoPayload,
  transformAddToCartPayload,
  transformBeginCheckoutPayload,
  transformPurchasePayload,
  transformRemoveFromCartPayload,
  transformViewItemPayload
} from '@services/transform';
import {
  ICartItem,
  ICheckoutData,
  IGAMAddPaymentInfoPayload,
  IGAMAddShippingInfoPayload,
  IGAMAddToCartPayload,
  IGAMBeginCheckoutPayload,
  IGAMData,
  IGAMPayload,
  IGAMPurchasePayload,
  IGAMRemoveFromCartPayload,
  IGAMViewItemPayload,
  IOrder,
  IProduct,
  ITrackTypePurchase
} from '@types';

class GAMService {
  private dataLayerPush(payload: IGAMData) {
    try {
      if (typeof window !== 'undefined' && PRODUCTION_ENV) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push(payload);
      }
    } catch (error) {
      console.error('Error pushing data to dataLayer:', error);
    }
  }

  trackBeginCheckout(data: ICheckoutData) {
    const transformedData = transformBeginCheckoutPayload(data);
    if (!transformedData) return;
    const payload: IGAMPayload<IGAMBeginCheckoutPayload> = {
      event: 'begin_checkout',
      ecommerce: transformedData
    };
    this.dataLayerPush(payload);
  }

  trackPurchase(data: IOrder, type: ITrackTypePurchase) {
    const transformedData = transformPurchasePayload(data, type);
    if (!transformedData) return;
    const payload: IGAMPayload<IGAMPurchasePayload> = {
      event: 'purchase',
      ecommerce: transformedData
    };
    this.dataLayerPush(payload);
  }

  trackViewItem(data: IProduct) {
    const transformedData = transformViewItemPayload(data);
    if (!transformedData) return;
    const payload: IGAMPayload<IGAMViewItemPayload> = {
      event: 'view_item',
      ecommerce: transformedData
    };
    this.dataLayerPush(payload);
  }

  trackRemoveFromCart(data: ICartItem) {
    const transformedData = transformRemoveFromCartPayload(data);
    if (!transformedData) return;
    const payload: IGAMPayload<IGAMRemoveFromCartPayload> = {
      event: 'remove_from_cart',
      ecommerce: transformedData
    };
    this.dataLayerPush(payload);
  }

  trackAddToCart(data: ICartItem) {
    const transformedData = transformAddToCartPayload(data);
    if (!transformedData) return;
    const payload: IGAMPayload<IGAMAddToCartPayload> = {
      event: 'add_to_cart',
      ecommerce: transformedData
    };
    this.dataLayerPush(payload);
  }

  trackAddPaymentInfo(checkoutData: Partial<ICheckoutData>) {
    const transformedData = transformAddPaymentInfoPayload(checkoutData);
    if (!transformedData) return;
    const payload: IGAMPayload<IGAMAddPaymentInfoPayload> = {
      event: 'add_payment_info',
      ecommerce: transformedData
    };
    this.dataLayerPush(payload);
  }

  trackAddShippingInfo(checkoutData: ICheckoutData | undefined) {
    const transformedData = transformAddShippingInfoPayload(checkoutData);
    if (!transformedData) return;
    const payload: IGAMPayload<IGAMAddShippingInfoPayload> = {
      event: 'add_shipping_info',
      ecommerce: transformedData
    };
    this.dataLayerPush(payload);
  }
}

export const GAMTrackService = new GAMService();
