import { API_URL, LOCAL_ENV } from '@lib/constants/environment';

export async function postErrorMessage(errorMessage: unknown): Promise<unknown> {
  if (!LOCAL_ENV) {
    const message = JSON.stringify(errorMessage);

    // * skip logging for "Loading chunk" errors
    if (message.includes('Loading chunk')) {
      return Promise.resolve();
    }

    return await fetch(`${API_URL}/error-log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(errorMessage),
      cache: 'no-cache'
    });
  }
  return Promise.resolve();
}
