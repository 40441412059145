export const ERROR_MESSAGES = {
  commonError: 'Виникла помилка.',
  password_incorrect: 'Невірно введений пароль.',
  current_password_incorrect: 'Старий пароль не вірний.',
  unauthorized: 'Помилка авторизації.',
  user_not_found: 'Користувача з таким телефоном не існує.',
  user_has_no_access: 'Схоже що акаунт деактивовано.',
  incorrectCode: 'Невірний код.',
  'Email already exists': 'Такий емейл вже існує.',
  'Phone already exists': 'Такий телефон вже існує.',
  'Already used': 'Користувач з таким номером телефону вже існує.',
  'inactive-product': 'Товар не існує.'
};

export const ERROR_MESSAGES_400 = {
  authId: {
    required: 'Телефон є обовʼязковим.',
    invalid: 'Телефон або email невірний.'
  },
  phone: {
    required: 'Телефон є обовʼязковим.',
    invalid: 'Телефон невірний.',
    'already-used': 'Користувач з таким номером телефону вже існує.',
    'already-verified': 'Користувач з таким телефоном уже веріфікований.'
  },
  email: {
    required: 'Емейл є обовʼязковим.',
    invalid: 'Емейл невірний.',
    'already-used': 'Користувач з таким емейлом вже існує.',
    'already-verified': 'Користувач з таким емейлом уже веріфікований.'
  },
  password: {
    required: 'Пороль є обовʼязковим.',
    incorrect: 'Невірно введений пароль.'
  },
  user: {
    'not-found': 'Користувача з таким телефоном не існує.',
    'already-verified': 'Користувач з таким телефоном уже веріфікований.',
    'unable-to-resolve': 'Користувача не знайдено'
  },
  token: {
    required: 'Токен є обовʼязковим.',
    invalid: 'Токен невірний.'
  },
  resetKey: {
    required: 'Код є обовʼязковим.',
    invalid: 'Код невірний.',
    expired: 'Код вже не дійсний.'
  },
  code: {
    timeout: 'Термін дії коду закінчився.',
    expired: 'Термін дії коду закінчився.',
    'already-used': 'Промокод вже був використанний.',
    'invalid-code': 'Код не дійсний.'
  },
  verificationCode: {
    required: 'Код є обовʼязковим.',
    invalid: 'Код невірний.',
    corrupted: 'Код не дійсний.'
  },
  product: {
    required: 'Товар є обовʼязковим.',
    invalid: 'Товар невірний.'
  },
  rating: {
    required: 'Рейтинг є обовʼязковим.',
    invalid: 'Рейтинг невірний.'
  },
  review: {
    required: 'Відгук є обовʼязковим.',
    invalid: 'Відгук невірний.',
    'too-short': 'Відгук занадто короткий.',
    'already-exists': 'Відгук вже існує.'
  },
  order: {
    required: 'Замовлення є обовʼязковим.',
    invalid: 'Замовлення невірне.',
    cancelled: 'Замовлення скасовано.'
  },
  name: {
    required: "Ім'я є обовʼязковим."
  },
  feedback: {
    required: 'Відгук є обовʼязковим.',
    invalid: 'Відгук невірний.',
    'too-short': 'Відгук занадто короткий.',
    'already-exists': 'Відгук вже присутній',
    exists: 'Відгук вже є.',
    'not-allowed': 'Відгук неможливо залишити'
  },
  checkout: 'Помилка при оформленні замовлення.',
  payment: {
    'already-paid': 'Рахунок вже оплачено.',
    cancelled: 'Рахунок скасовано.',
    'not-allowed': 'Оплата неможлива.'
  }
};
