export type ICountriesMap = keyof typeof countriesMap;

export const countriesMap = {
  ad: { code: 'AD', name: 'Андорра' },
  ae: { code: 'AE', name: "Об'Єднані Арабські Емірати" },
  af: { code: 'AF', name: 'Афганістан' },
  ag: { code: 'AG', name: 'Антиґуа І Барбуда' },
  ai: { code: 'AI', name: 'Анґілья' },
  al: { code: 'AL', name: 'Албанія' },
  am: { code: 'AM', name: 'Вірменія' },
  an: { code: 'AN', name: 'Нідерландські Антильські Острови' },
  ao: { code: 'AO', name: 'Анґола' },
  aq: { code: 'AQ', name: 'Антарктида' },
  ar: { code: 'AR', name: 'Аргентина' },
  as: { code: 'AS', name: 'Американське Самоа' },
  at: { code: 'AT', name: 'Австрія' },
  au: { code: 'AU', name: 'Австралія' },
  aw: { code: 'AW', name: 'Аруба' },
  az: { code: 'AZ', name: 'Азербайджан' },
  ba: { code: 'BA', name: 'Боснія І Герцеговина' },
  bb: { code: 'BB', name: 'Барбадос' },
  bd: { code: 'BD', name: 'Бангладеш' },
  be: { code: 'BE', name: 'Бельгія' },
  bf: { code: 'BF', name: 'Буркіна-фасо' },
  bg: { code: 'BG', name: 'Болгарія' },
  bh: { code: 'BH', name: 'Бахрейн' },
  bi: { code: 'BI', name: 'Бурунді' },
  bj: { code: 'BJ', name: 'Бенін' },
  bm: { code: 'BM', name: 'Бермуди' },
  bn: { code: 'BN', name: 'Бруней-даруссалам' },
  bo: { code: 'BO', name: 'Болівія' },
  br: { code: 'BR', name: 'Бразілія' },
  bs: { code: 'BS', name: 'Багами' },
  bt: { code: 'BT', name: 'Бутан' },
  bv: { code: 'BV', name: 'Острів Буве' },
  bw: { code: 'BW', name: 'Ботсвана' },
  by: { code: 'BY', name: 'Білорусь' },
  bz: { code: 'BZ', name: 'Беліз' },
  ca: { code: 'CA', name: 'Канада' },
  cc: { code: 'CC', name: 'Кокосові (Кілінґ) Острови' },
  cd: { code: 'CD', name: 'Демократична Республіка Конґо' },
  cf: { code: 'CF', name: 'Центральноафриканська Республіка' },
  cg: { code: 'CG', name: 'Конґо' },
  ch: { code: 'CH', name: 'Швейцарія' },
  ci: { code: 'CI', name: "Кот-д'Ївуар" },
  ck: { code: 'CK', name: 'Острови Кука' },
  cl: { code: 'CL', name: 'Чілі' },
  cm: { code: 'CM', name: 'Камерун' },
  cn: { code: 'CN', name: 'Китай' },
  co: { code: 'CO', name: 'Колумбія' },
  cr: { code: 'CR', name: 'Коста-Рика' },
  cu: { code: 'CU', name: 'Куба' },
  cv: { code: 'CV', name: 'Кабо-верде' },
  cx: { code: 'CX', name: 'Острів Різдва' },
  cy: { code: 'CY', name: 'Кіпр' },
  cz: { code: 'CZ', name: 'Чеська Республіка' },
  de: { code: 'DE', name: 'Німеччина' },
  dj: { code: 'DJ', name: 'Джібуті' },
  dk: { code: 'DK', name: 'Данія' },
  dm: { code: 'DM', name: 'Домініка' },
  do: { code: 'DO', name: 'Домініканська Республіка' },
  dz: { code: 'DZ', name: 'Алжир' },
  ec: { code: 'EC', name: 'Еквадор' },
  ee: { code: 'EE', name: 'Естонія' },
  eg: { code: 'EG', name: 'Єгипет' },
  eh: { code: 'EH', name: 'Західна Сахара' },
  er: { code: 'ER', name: 'Ерітрея' },
  es: { code: 'ES', name: 'Іспанія' },
  et: { code: 'ET', name: 'Ефіопія' },
  fi: { code: 'FI', name: 'Фінляндія' },
  fj: { code: 'FJ', name: 'Фіджі' },
  fk: { code: 'FK', name: 'Фолклендські Острови' },
  fm: { code: 'FM', name: 'Мікронезія, Федеративні Штати' },
  fo: { code: 'FO', name: 'Фарерські Острови' },
  fr: { code: 'FR', name: 'Франція' },
  ga: { code: 'GA', name: 'Ґабон' },
  gb: { code: 'GB', name: 'Велика Британія' },
  gd: { code: 'GD', name: 'Ґренада' },
  ge: { code: 'GE', name: 'Грузія' },
  gf: { code: 'GF', name: 'Французька Ґвіана' },
  gh: { code: 'GH', name: 'Гана' },
  gi: { code: 'GI', name: 'Ґібралтар' },
  gl: { code: 'GL', name: 'Ґренландія' },
  gm: { code: 'GM', name: 'Ґамбія' },
  gn: { code: 'GN', name: 'Ґвінея' },
  gp: { code: 'GP', name: 'Ґваделупа' },
  gq: { code: 'GQ', name: 'Екваторіальна Ґвінея' },
  gr: { code: 'GR', name: 'Греція' },
  gs: { code: 'GS', name: 'Південна Джорджія та Південні Сандвічеві Острови' },
  gt: { code: 'GT', name: 'Гватемала' },
  gu: { code: 'GU', name: 'Ґуам' },
  gw: { code: 'GW', name: 'Ґвінея-бісау' },
  gy: { code: 'GY', name: 'Ґайана' },
  hk: { code: 'HK', name: 'Гонконг' },
  hm: { code: 'HM', name: 'Острів Херд і Острови Макдональд' },
  hn: { code: 'HN', name: 'Гондурас' },
  hr: { code: 'HR', name: 'Хорватія' },
  ht: { code: 'HT', name: 'Гаїті' },
  hu: { code: 'HU', name: 'Угорщина' },
  id: { code: 'ID', name: 'Індонезія' },
  ie: { code: 'IE', name: 'Ірландія' },
  il: { code: 'IL', name: 'Ізраїль' },
  in: { code: 'IN', name: 'Індія' },
  io: { code: 'IO', name: 'Британська Територія в Індійському Океані' },
  iq: { code: 'IQ', name: 'Ірак' },
  ir: { code: 'IR', name: 'Іран' },
  is: { code: 'IS', name: 'Ісландія' },
  it: { code: 'IT', name: 'Італія' },
  jm: { code: 'JM', name: 'Ямайка' },
  jo: { code: 'JO', name: 'Йорданія' },
  jp: { code: 'JP', name: 'Японія' },
  ke: { code: 'KE', name: 'Кенія' },
  kg: { code: 'KG', name: 'Киргизстан' },
  kh: { code: 'KH', name: 'Камбоджа' },
  ki: { code: 'KI', name: 'Кірібаті' },
  km: { code: 'KM', name: 'Комори' },
  kn: { code: 'KN', name: 'Сент-кітс і Невіс' },
  kp: { code: 'KP', name: 'Кндр' },
  kr: { code: 'KR', name: 'Республіка Корея' },
  kw: { code: 'KW', name: 'Кувейт' },
  ky: { code: 'KY', name: 'Острови Кайман' },
  kz: { code: 'KZ', name: 'Казахстан' },
  la: { code: 'LA', name: 'Лаоська Народно-демократична Республіка' },
  lb: { code: 'LB', name: 'Ліван' },
  lc: { code: 'LC', name: 'Сент-люсія' },
  li: { code: 'LI', name: 'Ліхтенштейн' },
  lk: { code: 'LK', name: 'Шрі-Ланка' },
  lr: { code: 'LR', name: 'Ліберія' },
  ls: { code: 'LS', name: 'Лесото' },
  lt: { code: 'LT', name: 'Литва' },
  lu: { code: 'LU', name: 'Люксембург' },
  lv: { code: 'LV', name: 'Латвія' },
  ly: { code: 'LY', name: 'Лівійська Арабська Джамахірія' },
  ma: { code: 'MA', name: 'Марокко' },
  mc: { code: 'MC', name: 'Монако' },
  md: { code: 'MD', name: 'Молдова' },
  mg: { code: 'MG', name: 'Мадаґаскар' },
  mh: { code: 'MH', name: 'Маршаллові Острови' },
  mk: { code: 'MK', name: 'Македонія, Колишня Югославська Республіка' },
  ml: { code: 'ML', name: 'Малі' },
  mm: { code: 'MM', name: "М'Янма" },
  mn: { code: 'MN', name: 'Монголія' },
  mo: { code: 'MO', name: 'Макао' },
  mp: { code: 'MP', name: 'Північні Маріанські Острови' },
  mq: { code: 'MQ', name: 'Мартініка' },
  mr: { code: 'MR', name: 'Мавританія' },
  ms: { code: 'MS', name: 'Монтсеррат' },
  mt: { code: 'MT', name: 'Мальта' },
  mu: { code: 'MU', name: 'Маврикій' },
  mv: { code: 'MV', name: 'Мальдіви' },
  mw: { code: 'MW', name: 'Малаві' },
  mx: { code: 'MX', name: 'Мексика' },
  my: { code: 'MY', name: 'Малайзія' },
  mz: { code: 'MZ', name: 'Мозамбік' },
  na: { code: 'NA', name: 'Намібія' },
  nc: { code: 'NC', name: 'Нова Каледонія' },
  ne: { code: 'NE', name: 'Ніґер' },
  nf: { code: 'NF', name: 'Острів Норфолк' },
  ng: { code: 'NG', name: 'Ніґерія' },
  ni: { code: 'NI', name: 'Нікарагуа' },
  nl: { code: 'NL', name: 'Нідерланди' },
  no: { code: 'NO', name: 'Норвеґія' },
  np: { code: 'NP', name: 'Непал' },
  nr: { code: 'NR', name: 'Науру' },
  nu: { code: 'NU', name: 'Ніуе' },
  nz: { code: 'NZ', name: 'Нова Зеландія' },
  om: { code: 'OM', name: 'Оман' },
  pa: { code: 'PA', name: 'Панама' },
  pe: { code: 'PE', name: 'Перу' },
  pf: { code: 'PF', name: 'Французька Полінезія' },
  pg: { code: 'PG', name: 'Папуа-нова Ґвінея' },
  ph: { code: 'PH', name: 'Філіппіни' },
  pl: { code: 'PL', name: 'Польща' },
  pm: { code: 'PM', name: "Сен-п'єр і Мікелон" },
  pn: { code: 'PN', name: 'Піткерн' },
  pr: { code: 'PR', name: 'Пуерто-рико' },
  ps: { code: 'PS', name: 'Палестина' },
  pt: { code: 'PT', name: 'Португалія' },
  pw: { code: 'PW', name: 'Палау' },
  py: { code: 'PY', name: 'Параґвай' },
  qa: { code: 'QA', name: 'Катар' },
  re: { code: 'RE', name: 'Реюньйон' },
  ro: { code: 'RO', name: 'Румунія' },
  ru: { code: 'RU', name: 'Російська Федерація' },
  rw: { code: 'RW', name: 'Руанда' },
  sa: { code: 'SA', name: 'Саудівська Аравія' },
  sb: { code: 'SB', name: 'Соломонові Острови' },
  sc: { code: 'SC', name: 'Сейшели' },
  sd: { code: 'SD', name: 'Судан' },
  se: { code: 'SE', name: 'Швеція' },
  sg: { code: 'SG', name: 'Сінгапур' },
  sh: { code: 'SH', name: 'Острів Святої Єлени' },
  si: { code: 'SI', name: 'Словенія' },
  sj: { code: 'SJ', name: 'Острови Свальбард та Ян‑маєн' },
  sk: { code: 'SK', name: 'Словаччина' },
  sl: { code: 'SL', name: 'Сьєрра-леоне' },
  sm: { code: 'SM', name: 'Сан-маріно' },
  sn: { code: 'SN', name: 'Сенеґал' },
  so: { code: 'SO', name: 'Сомалі' },
  sr: { code: 'SR', name: 'Сурінам' },
  st: { code: 'ST', name: 'Сан-томе і Прінсіпі' },
  sv: { code: 'SV', name: 'Сальвадор' },
  sy: { code: 'SY', name: 'Сірійська Арабська Республіка' },
  sz: { code: 'SZ', name: 'Свазіленд' },
  tc: { code: 'TC', name: 'Теркс і Кайкос, Острови' },
  td: { code: 'TD', name: 'Чад' },
  tf: { code: 'TF', name: 'Французькі Південні Території' },
  tg: { code: 'TG', name: 'Того' },
  th: { code: 'TH', name: 'Таїланд' },
  tj: { code: 'TJ', name: 'Таджикистан' },
  tk: { code: 'TK', name: 'Токелау' },
  tm: { code: 'TM', name: 'Туркменістан' },
  tn: { code: 'TN', name: 'Туніс' },
  to: { code: 'TO', name: 'Тонґа' },
  tp: { code: 'TP', name: 'Східний Тімор' },
  tr: { code: 'TR', name: 'Туреччина' },
  tt: { code: 'TT', name: 'Тринідад І Тобаґо' },
  tv: { code: 'TV', name: 'Тувалу' },
  tw: { code: 'TW', name: 'Тайвань, Провінція Китаю' },
  tz: { code: 'TZ', name: "Об'єднана Республіка Танзанія" },
  ua: { code: 'UA', name: 'Україна' },
  ug: { code: 'UG', name: 'Уганда' },
  um: { code: 'UM', name: 'Малі Віддалені Острови Сполучених Штатів' },
  us: { code: 'US', name: 'США' },
  uy: { code: 'UY', name: 'Уругвай' },
  uz: { code: 'UZ', name: 'Узбекистан' },
  va: { code: 'VA', name: 'Папський Престол' },
  vc: { code: 'VC', name: 'Сент-вінсент 1 Ґренадіни' },
  ve: { code: 'VE', name: 'Венесуела' },
  vg: { code: 'VG', name: 'Вірґінські Острови, Британські*' },
  vi: { code: 'VI', name: 'Вірґінські Острови, Сша *' },
  vn: { code: 'VN', name: "В'Єтнам" },
  vu: { code: 'VU', name: 'Вануату' },
  wf: { code: 'WF', name: 'Уолліс І Футуна' },
  ws: { code: 'WS', name: 'Самоа' },
  ye: { code: 'YE', name: 'Ємен' },
  yn: { code: 'YN', name: 'Майотта' },
  yu: { code: 'YU', name: 'Югославія' },
  za: { code: 'ZA', name: 'Південна Африка' },
  zm: { code: 'ZM', name: 'Замбія' },
  zr: { code: 'ZR', name: 'Заїр' },
  zw: { code: 'ZW', name: 'Зімбабве' },
  pk: { code: 'pk', name: 'Пакистан' }
};
