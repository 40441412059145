'use client';

import { useEffect, useRef, useState } from 'react';

const useLiqpayPayment = () => {
  const paymentForm = useRef<HTMLFormElement>(null);
  const [payData, setPayData] = useState('');
  const [paySignature, setPaySignature] = useState('');

  useEffect(() => {
    if (payData && paySignature && paymentForm.current && paymentForm.current.submit) {
      paymentForm.current.submit();
    }
  }, [payData, paySignature]);

  const PaymentForm = (
    <form ref={paymentForm} method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
      <input type="hidden" name="data" value={payData} />
      <input type="hidden" name="signature" value={paySignature} />
    </form>
  );

  return { setPayData, setPaySignature, PaymentForm };
};

export { useLiqpayPayment };
